<template>
  <div id="NoDataFound" class="border rounded-md bg-white">
    <div class="mx-auto text-center pt-40 pb-10 text-primary-100">
      <i :class="iconClass" class="text-7xl" aria-hidden="true"></i>
      <div class="my-6 text-sm">{{ message }}</div>
    </div>
  </div>
</template>

<style lang="scss">
#NoDataFound {
  .noDataMessage {
    img {
      width: 70px;
      opacity: 0.6;
      margin-bottom: 10px;
    }

    .smile {
      font-size: 50px;
      color: #9ede7f;
    }
  }

  .integrationLink {
    vertical-align: bottom;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding-bottom: 20px;
    font-size: 12px;
  }
}
</style>

<script>
import NoDataComponent from './noDataComponent';
export default NoDataComponent;
</script>
