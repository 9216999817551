const axios = require('axios');
const { baseUrlNew, buildAuthHeaders } = require('./settings.js');

exports.fetchWebMessageList = function (messageType) {
  return axios.get(baseUrlNew + `web-message/list/${messageType}`, {
    headers: buildAuthHeaders()
  });
};

exports.getReport = function (id, params) {
  return axios.post(baseUrlNew + `web-message/report/${id}`, params, {
    headers: buildAuthHeaders()
  });
};

exports.getCustomerList = function (id, params) {
  return axios.post(baseUrlNew + `web-message/report/${id}/customers`, params, {
    headers: buildAuthHeaders()
  });
};

exports.getDetails = function (id) {
  return axios.get(baseUrlNew + `web-message/detail/${id}`, {
    headers: buildAuthHeaders()
  });
};

exports.getTemplateContent = function (templateId) {
  return axios.get(baseUrlNew + `web-message/template/${templateId}`, {
    headers: buildAuthHeaders()
  });
};

exports.createDraft = function (params) {
  return axios.post(baseUrlNew + `web-message/draft`, params, {
    headers: buildAuthHeaders()
  });
};

exports.update = function (id, params) {
  return axios.put(baseUrlNew + `web-message/update/${id}`, params, {
    headers: buildAuthHeaders()
  });
};

exports.updateStatus = function (id, params) {
  return axios.put(baseUrlNew + `web-message/update/${id}/status`, params, {
    headers: buildAuthHeaders()
  });
};

exports.delete = function (id) {
  return axios.delete(baseUrlNew + `web-message/${id}`, {
    headers: buildAuthHeaders()
  });
};

exports.clone = function (id) {
  return axios.post(baseUrlNew + `web-message/clone/${id}`, {
    headers: buildAuthHeaders()
  });
};
