export default {
  name: 'noData',
  props: {
    message: {
      required: false,
      default: 'No data found'
    },
    iconClass: {
      required: false,
      default: 'fa fa-users'
    }
  },
  data() {
    return {};
  },
  methods: {},
  created() {}
};
