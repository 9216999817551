<template>
  <div id="webMessageReportPage">
    <!-- Page Loader -->
    <div class="loadingDiv" v-if="fetchingWebMessageDetails" v-loading="true" style="min-height: 300px; width: 100%"></div>

    <!-- Page Header -->
    <div class="pageHeader border rounded-md bg-white px-3 py-3" v-if="!fetchingWebMessageDetails" shadow="never">
      <router-link :to="'/webMessage/' + webMessage.messageType" style="cursor: pointer">
        <i class="el-icon-back text-black"></i>
      </router-link>
      &nbsp;&nbsp;
      {{ webMessage.name }} &nbsp; ({{ webMessage.id }}) &nbsp;&nbsp;&nbsp;&nbsp;

      <!-- Status -->
      <el-tag v-if="webMessage.status == 'running'" type="success" effect="dark" size="mini">
        {{ webMessage.status.toUpperCase() }}
      </el-tag>
      <el-tag v-else type="danger" effect="dark" size="mini">
        {{ webMessage.status.toUpperCase() }}
      </el-tag>

      <!-- Action Button -->
      <div class="actionBtn">
        <span class="actionBtnDivision">
          <!-- Edit -->
          <el-tooltip class="item" effect="dark" content="Edit" placement="bottom">
            <el-button size="mini" type="primary" icon="el-icon-edit" circle plain class="editBtn" @click="editWebMessage()"></el-button>
          </el-tooltip>
          <!-- Play/Pause -->
          <el-tooltip class="item" effect="dark" :content="webMessage.status === 'running' ? 'Pause' : 'Resume'" placement="bottom">
            <el-button size="mini" :loading="updatingStatus" type="primary" icon="el-icon-video-pause" circle plain v-if="webMessage.status === 'running'" @click="updateStatus('paused')"></el-button>
            <el-button size="mini" :loading="updatingStatus" type="primary" icon="el-icon-video-play" circle plain v-else-if="webMessage.status === 'paused'" @click="updateStatus('running')"></el-button>
          </el-tooltip>
          <!-- Clone -->
          <el-tooltip class="item" effect="dark" content="Create Copy" placement="bottom">
            <el-button type="primary" size="mini" plain icon="el-icon-document-copy" v-loading.fullscreen.lock="showSaveLoader" @click="cloneWebMessage()" circle></el-button>
          </el-tooltip>
          <!-- Delete -->
          <el-tooltip class="item" effect="dark" content="Delete" placement="bottom">
            <el-button size="mini" type="danger" icon="el-icon-delete" circle plain class="deleteBtn" @click="deleteWebMessage()"></el-button>
          </el-tooltip>
        </span>
      </div>
    </div>

    <!-- Analytics Chart -->
    <el-card class="overviewCard analyticsCard" shadow="never" v-if="!fetchingWebMessageDetails">
      <div class="cardHeader" slot="header">
        <span>Detailed Statistics</span>

        <!-- Time picker -->
        <div class="datePicker rightSideOptions">
          <el-select size="mini" class="timingGroup" v-model="webMessageStatsTimingFilter" v-show="webMessageStatsTimingFilter != ''">
            <el-option v-for="(value, key) in sessionTimingOptions" :key="key" :label="value" :value="key"> </el-option>
          </el-select>
          <el-date-picker v-if="webMessageStatsTimingFilter == ''" v-model="webMessageStatsTiming.dateFilterValues" size="mini" type="datetimerange" :picker-options="datePickerShortcutList" format="dd-MM-yyyy hh:mm:ss A" @change="fetchWebMessageDetails"> </el-date-picker>
        </div>
        <!-- Timing Group -->
        <el-select class="timingGroup rightSideOptions" v-model="webMessageStatsTiming.durationGroup" size="mini" placeholder="Select" @change="fetchWebMessageDetails()">
          <el-option v-for="item in webMessageStatsTiming.groupByDurationList" :key="item.value" :label="item.key" :value="item.value"> </el-option>
        </el-select>
      </div>
      <div class="chartContainer">
        <DynamicTimelineGraphComponent v-bind:graph="webMessageStatsGraphData"></DynamicTimelineGraphComponent>
      </div>
    </el-card>

    <!-- Customer List -->
    <template>
      <div style="margin-top: 30px; font-size: 20px; margin-bottom: 5px">Conversions</div>

      <!-- Loader -->
      <div class="loadingDiv" v-if="fetchingCustomerList" v-loading="true" style="min-height: 300px; width: 100%"></div>

      <!-- No Data Found -->
      <NoDataComponent message="No form submittions done yet." v-if="!fetchingCustomerList && customerList.length == 0"></NoDataComponent>

      <!-- Customer List Table -->
      <div v-loading="fetchingCustomerList" class="customerListTableDiv" v-if="!fetchingCustomerList && customerList.length > 0">
        <ul class="list-group">
          <!-- Top Pagination -->
          <div class="pagination pagination-top" style="border-bottom: 1px solid #ebeef4">
            <div class="totalCount">Total {{ customerListPaginationInfo.total }} Conversions</div>
            <el-pagination @size-change="customerListPageSizeChange" @current-change="customerListPageChange" :current-page.sync="customerListPaginationInfo.currentPage" :page-sizes="[50, 100, 200]" :page-size="customerListPaginationInfo.currentPageSize" layout="sizes, prev, pager, next, jumper" :total="customerListPaginationInfo.total"></el-pagination>
          </div>

          <!-- Customer List -->
          <el-table :data="customerList" style="width: 100%; border-left: 1px solid #eee; border-right: 1px solid #eee">
            <el-table-column prop="name" label="Name">
              <template slot-scope="scope">
                <a :href="`/customer/detail?id=${scope.row.id}`" target="_blank" style="margin-left: 10px" class="text-primary-600 hover:text-primary-600">
                  {{ scope.row.name ? scope.row.name : 'Name Not Available' }}
                </a>
              </template>
            </el-table-column>
            <el-table-column prop="email" label="Email" width="375"> </el-table-column>
            <el-table-column prop="mobile" label="Mobile" width="250">
              <template slot-scope="scope">
                <span>{{ scope.row.mobile ? scope.row.mobile : 'Mobile No Not Available' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Conversion Time" width="250">
              <template slot-scope="scope">
                <span>{{ getUptimeDisplayTime(scope.row.created_at) }}</span>
              </template>
            </el-table-column>
          </el-table>

          <!-- Bottom Pagination -->
          <div class="pagination pagination-bottom">
            <div class="totalCount">Total {{ customerListPaginationInfo.total }} Conversions</div>
            <el-pagination @size-change="customerListPageSizeChange" @current-change="customerListPageChange" :current-page.sync="customerListPaginationInfo.currentPage" :page-sizes="[50, 100, 200]" :page-size="customerListPaginationInfo.currentPageSize" layout="sizes, prev, pager, next, jumper" :total="customerListPaginationInfo.total"></el-pagination>
          </div>
        </ul>
      </div>
    </template>
  </div>
</template>

<style lang="scss" src="./webMessageReport.scss"></style>

<script>
import webMessageReportComponent from './webMessageReportComponent';
export default webMessageReportComponent;
</script>
