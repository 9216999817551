const moment = require('moment');
import webMessageModule from '@/services/webMessage';
import DynamicTimelineGraphComponent from '@/components/graphs/dynamicTimelineGraph/dynamicTimelineGraph';
import NoDataComponent from '@/pages/others/noData/noData';

export default {
  name: 'WebMessageReport',
  components: {
    DynamicTimelineGraphComponent,
    NoDataComponent
  },
  data() {
    return {
      messageId: null,
      webMessage: null,

      // WebMessage Stats
      webMessageStatsTiming: {
        dateFilterValues: [],
        durationType: 'latest',
        durationGroup: 'day',
        groupByDurationList: [
          {
            key: 'Hourly',
            value: 'hour'
          },
          {
            key: 'Daily',
            value: 'day'
          },
          {
            key: 'Weekly',
            value: 'week'
          },
          {
            key: 'Monthly',
            value: 'month'
          }
        ]
      },
      webMessageStatsTimingFilter: '43200',
      fetchingWebMessageDetails: true,
      webMessageStatsGraphData: null,

      // Action Status
      updatingStatus: false,

      // Converted Customers List
      fetchingCustomerList: true,
      customerList: null,
      onlyRegisteredCustomers: false,
      customerListPaginationInfo: {
        currentPage: 1,
        currentPageSize: 50,
        total: null
      }
    };
  },
  watch: {
    webMessageStatsTimingFilter: {
      handler: function () {
        if (this.webMessageStatsTimingFilter) {
          this.groupByDurationList = this.getGroupByDurationList(this.webMessageStatsTimingFilter);
          this.webMessageStatsTiming.durationGroup = this.groupByDurationList[0].value;
          this.onStatsTimingChange();
        }
      }
    }
  },
  methods: {
    onStatsTimingChange() {
      this.fetchWebMessageDetails();
    },

    async fetchWebMessageDetails() {
      // Fetch data
      try {
        this.fetchingWebMessageDetails = true;

        if (this.webMessageStatsTimingFilter === '') {
          this.startTime = moment(this.webMessageStatsTiming.dateFilterValues[0]);
          this.endTime = moment(this.webMessageStatsTiming.dateFilterValues[1]);
          this.webMessageStatsTiming.durationType = 'exact';
        } else {
          this.startTime = moment().subtract(this.webMessageStatsTimingFilter, 'minutes');
          this.endTime = moment();
          this.webMessageStatsTiming.durationType = 'latest';
        }

        let params = {
          startTime: this.startTime.format('YYYY-MM-DD HH:mm:ss'),
          endTime: this.endTime.format('YYYY-MM-DD HH:mm:ss'),
          durationGroup: this.webMessageStatsTiming.durationGroup
        };

        let result = await webMessageModule.getReport(this.messageId, params, this);

        this.webMessage = result.data.message;
        this.webMessageStatsGraphData = {
          data: result.data.stats,
          durationGroup: this.webMessageStatsTiming.durationGroup,
          durationType: this.webMessageStatsTiming.durationType,
          duration: this.webMessageStatsTimingFilter,
          startTime: this.startTime.format('YYYY-MM-DD HH:mm:ss'),
          endTime: this.endTime.format('YYYY-MM-DD HH:mm:ss')
        };

        this.fetchingWebMessageDetails = false;
      } catch (err) {
        this.reportError(err);
        this.errorToast('Something went wrong.');
        this.fetchingWebMessageDetails = false;
      }
    },

    //#region ---------------- End: Customer List Methods ------------------------

    async fetchCustomers() {
      try {
        this.fetchingCustomerList = true;

        var params = {
          pageNumber: this.customerListPaginationInfo.currentPage,
          pageSize: this.customerListPaginationInfo.currentPageSize
        };
        let result = await webMessageModule.getCustomerList(this.messageId, params, this);
        this.customerList = result.data.data;
        this.customerListPaginationInfo.total = result.data.pagination.count;

        this.fetchingCustomerList = false;
      } catch (error) {
        console.log(error);
        this.fetchingCustomerList = false;
        this.errorToast('Something went wrong.');
      }
    },

    customerListPageSizeChange(val) {
      this.customerListPaginationInfo.currentPage = 1;
      this.customerListPaginationInfo.currentPageSize = val;
      this.customerListPaginationInfo.total = null;
      this.fetchCustomers();
    },

    customerListPageChange(val) {
      this.currentPage = val;
      this.fetchCustomers();
    },

    getCustomerDetailLink(customerId, timestamp) {
      return '/customer/detail?id=' + customerId + '&timestamp=' + timestamp;
    },

    //#endregion ---------------- End: Customer List Methods ------------------------

    //#region ---------------- Start: Edit webMessage ------------------------

    // Edit Message Link
    editWebMessage() {
      this.$router.push({
        path: `/webMessage/${this.messageId}/edit`
      });
    },

    // Pause webMessage
    async updateStatus(newStatus) {
      try {
        this.updatingStatus = true;

        // Prepare input
        let params = {
          status: newStatus
        };

        await webMessageModule.updateStatus(this.webMessage.id, params, this);
        this.webMessage.status = newStatus;

        this.successToast((newStatus == 'running' ? 'Resume' : 'Pause') + ' successful.');
        this.updatingStatus = false;
      } catch (err) {
        this.updatingStatus = false;
        this.errorToast('Something went wrong. Please contact support.');
        this.reportError(err);
      }
    },

    // // delete webMessage
    deleteWebMessage() {
      this.$swal({
        title: `Delete Widget?`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(() => {
        setTimeout(async () => {
          try {
            await webMessageModule.delete(this.messageId, this);
            this.$router.push({
              path: `/webMessage/${this.webMessage.messageType}`
            });
            this.successToast(`Deleted successfully.`);
          } catch (e) {
            this.reportError(e);
            this.errorToast(`Something went wrong.`);
          }
        }, 50);
      });
    },

    // Clone webMessage
    async cloneWebMessage() {
      try {
        await this.$swal({
          title: 'Create Copy?',
          text: 'A new popup will be created with the same settings mentioned for this popup.',
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#1a535c',
          confirmButtonText: 'Create Copy'
        });
      } catch (err) {
        // Cacelled, Ignore
        return;
      }

      try {
        let response = await webMessageModule.clone(this.messageId, this);

        // Redirect to campaign report page.
        this.successToast(`Duplicate popup created successfully.`);
        location.href = `/webMessage/${response.data}/edit`;
      } catch (e) {
        this.reportError(e);
        this.errorToast(`Something went wrong.`);
      }
    }

    // // clone webMessage
    // cloneWebMessage() {
    //   this.$swal({
    //     title: 'Make a clone of webMessage?',
    //     text: 'Are you sure you want to create a copy of this webMessage?',
    //     type: 'warning',
    //     showCancelButton: true,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Yes, clone it!'
    //   }).then(result => {
    //     setTimeout(() => {
    //       let params = {
    //         webMessageId: this.webMessageId
    //       };
    //       this.cloningCampaing = true;
    //       cloneWebMessageById(params)
    //         .then(result => {
    //           if (result.data.success) {
    //             this.webMessageId = result.data.data;
    //             this.fetchWebMessageReport();
    //           }
    //           this.successToast(`WebMessage duplicate successfully.`);
    //           this.cloningCampaing = false;
    //         })
    //         .catch(e => {
    //           this.reportError(e);
    //           this.errorToast(`Something went wrong.`);
    //           this.cloningCampaing = false;
    //         });
    //     }, 50);
    //   });
    // }

    //#endregion ---------------- End: Edit webMessage ------------------------
  },

  mounted() {
    this.messageId = this.$route.params.messageId;

    this.fetchWebMessageDetails();
    this.fetchCustomers();
  }
};
