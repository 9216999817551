const axios = require('axios');
const { baseUrlNew, analyticsUrl, buildAuthHeaders } = require('./settings.js');

exports.getEventList = function () {
  return axios.get(baseUrlNew + `event/metadata`, {
    headers: buildAuthHeaders()
  });
};

exports.getEventDynamicBarGraphData = function (params) {
  return axios.post(analyticsUrl + `graphs/bar`, params, {
    headers: buildAuthHeaders()
  });
};

exports.getEventDynamicTimelineGraphData = function (params) {
  return axios.post(analyticsUrl + `graphs/timeline`, params, {
    headers: buildAuthHeaders()
  });
};

exports.exportEventDynamicTimelineGraphData = function (params) {
  return axios.post(analyticsUrl + `graphs/timeline/export`, params, {
    headers: buildAuthHeaders()
  });
};

//#region -------------------- Event Details Page Methods -------------------- //

exports.getEventDetailPageGeneralData = function (params) {
  return axios.post(analyticsUrl + `event/quickView`, params, {
    headers: buildAuthHeaders()
  });
};
exports.getEventTimelineGraphData = function (params) {
  return axios.post(analyticsUrl + `event/timeline`, params, {
    headers: buildAuthHeaders()
  });
};

exports.getEventPropertyGraphData = function (params) {
  return axios.post(analyticsUrl + `event/byPropBarChart`, params, {
    headers: buildAuthHeaders()
  });
};

exports.fetchCustomEventValueSuggetionsList = function (params) {
  return axios.post(baseUrlNew + `event/suggestions`, params, {
    headers: buildAuthHeaders()
  });
};

exports.fetchCustomEventValueSuggetionsByCol = function (params) {
  return axios.post(baseUrlNew + `event/suggestionscol`, params, {
    headers: buildAuthHeaders()
  });
};

exports.fetchEventListByCustomerId = function (params) {
  return axios.post(baseUrlNew + `event/customer`, params, {
    headers: buildAuthHeaders()
  });
};

exports.getEventListByEventName = function (params) {
  return axios.post(analyticsUrl + `event/raw`, params, {
    headers: buildAuthHeaders()
  });
};

//#endregion -------------------- Event Details Page Methods -------------------- //
